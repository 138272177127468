import { useEffect, useState } from "react";
import {
  PATCH_DOCUMENT_DETAILS,
  SET_ACTIVE_STEP_INDEX,
  SET_STEP_VALIDITY,
  useCreateRequestContext,
} from "./CreateRequestContext";

import { Button, Card, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { API_URL } from "constants";
import { toast } from "react-toastify";
import { useTranslation } from "../../../contexts/TranslationContext";
import env from "shared/constants/env";
import { useLocation } from "react-router-dom";

export default function Document() {
  const { state, dispatch } = useCreateRequestContext();
  const { translate } = useTranslation();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const applId = searchParams.get("appl_");

  const [files, setFiles] = useState([]);
  const [fetchedFiles, setFetchedFiles] = useState([])
  const [initialDataLoading, setInitialDataLoading] = useState(true)

  const getDocuments = async () => {
    if (applId) {
      fetch(
        `${env.API_ENDPOINT}app/upload-document?appId=${applId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setFetchedFiles(data.payload)
          if (data.payload?.length === 3) {
            toast.info(translate("CREATE_REQUEST.MAX_FILES_UPLOADED"))
          }
        }
        )
        .catch(() => {
          toast.error("Failed to get documents.");
        })
        .finally(() => {
          setInitialDataLoading(false)
        })
    }
  }

  useEffect(() => {
    console.log('appId is>', applId)
    getDocuments()
  }, [])


  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 5 });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_STEP_VALIDITY,
      payload: {
        stepIndex: 5,
        valid: true,
      },
    });
  }, [dispatch]);

  const beforeUpload = (file) => {
    console.log("file", file);
    if (files.length + fetchedFiles.length >= 3) {
      alert("You can only upload up to three files!");
      return false;
    }

    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      alert("You can only upload JPG/PNG/PDF files!");
      return false;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      alert("File must be smaller than 3MB!");
      return false;
    }

    const fileName = file.name;
    // Regex to check for multiple extensions
    const multipleExtensionsPattern = /(\.[^.\s]{2,4}){2,}$/;
    if (multipleExtensionsPattern.test(fileName)) {
      alert("Files with multiple extensions are not allowed.");
      return false;
    }

    let newFiles = [...files, file];
    dispatch({ type: PATCH_DOCUMENT_DETAILS, payload: newFiles });
    setFiles(newFiles);
    return false;
  };

  if (initialDataLoading) {
    return (
      <div className='p-5 bg-white flex flex-col gap-4'>
        <div className='flex gap-4'>
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_DOCUMENT_DETAILS")}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <h2 className="font-bold text-xl mb-4">
        {translate("CREATE_REQUEST.DOCUMENT")}
      </h2>

      <div className="mb-4">
        <Card className="w-full">
          <Upload
            fileList={files}
            accept={"image/png,image/jpeg,image/jpg,application/pdf"}
            multiple={true}
            onRemove={(file) => {
              const index = files.indexOf(file);
              const newFileList = files.slice();
              newFileList.splice(index, 1);
              dispatch({ type: PATCH_DOCUMENT_DETAILS, payload: newFileList });
              setFiles(newFileList);
            }}
            maxCount={3}
            beforeUpload={beforeUpload}
          >
            {files.length + fetchedFiles.length < 3 && (
              <Button icon={<UploadOutlined />}>
                {translate("CREATE_REQUEST.CLICK_TO_UPLOAD")}
              </Button>
            )}
          </Upload>
        </Card>
        <ul className="p-5" style={{ listStyle: "numbered" }}>
          <li>{translate("CREATE_REQUEST.DOC_CONDITION_1")}</li>
          <li>{translate("CREATE_REQUEST.DOC_CONDITION_2")}</li>
          <li>
            {translate("CREATE_REQUEST.DOC_CONDITION_3")}{" "}
            <span className="font-bold">
              * ({translate("For Private Sector")})
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}
