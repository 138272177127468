import { Table as AntTable, Dropdown } from "antd";
import { RightOutlined, LeftOutlined, FileTextOutlined, TagsOutlined, TeamOutlined, ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";
import EyeIcon from "assets/icons/eye.svg";
import UploadIcon from "assets/icons/upload.svg";
import MoreVerticalIcon from "assets/icons/more-vertical.svg";
import { useNavigate } from "react-router-dom";
import routes from "shared/constants/routes";
import { toast } from "react-toastify";
import { API_URL } from "constants";
import { useTranslation } from "contexts/TranslationContext";

const MenuActionItem = ({ icon: Icon, label }) => {
  const { language } = useTranslation();
  return (
    <div className="flex items-center justify-between gap-8 py-2">
      <div className="flex items-center gap-2">
        <Icon />
        <div>{label}</div>
      </div>
      {language.code === 'ar' ? <LeftOutlined /> : <RightOutlined />}
    </div>
  );
}

export default function Table({ actions = [], columns = [], data = [], refresh = () => { }, onDelete = () => { }, onEdit = () => { }, ...rest }) {
  const navigate = useNavigate();
  const { translate } = useTranslation();

  const hasActions = Array.isArray(actions) && actions.length > 0;

  columns = hasActions
    ? [
      ...columns,
      {
        title: translate("ACTIONS"),
        render: (value, record) => (
          <div className="flex items-center gap-2">
            <div onClick={() => navigate("/app/" + routes.VIEW_REQUEST + "/" + record?.APPLID)} className="cursor-pointer bg-gray-100 hover:bg-gray-200 active:bg-gray-300 rounded-lg p-2">
              <img alt="" src={EyeIcon} />
            </div>
            {/*   <div className="cursor-pointer bg-gray-100 hover:bg-gray-200 active:bg-gray-300 rounded-lg p-2">
                <img alt="" src={UploadIcon} />
              </div> */}
            <div className="cursor-pointer bg-transparent rounded-lg p-2">
              <Dropdown
                menu={{
                  items: actions
                    .filter((a) => {
                      //console.log("a.label", a.label, record);
                      if (a.actionKey === "Offer") {
                        return record?.OFFER_STATUS && record?.OFFER_STATUS == "GENERATED";
                      }
                      if (a.actionKey === "Contract") {
                        return record?.CONTRACT_STATUS && record?.CONTRACT_STATUS == "GENERATED";
                      }
                      if (a.actionKey === "View Contract") {
                        return record?.CONTRACT_STATUS && record?.CONTRACT_STATUS == "SIGNED";
                      }
                      if (a.actionKey === "Edit") {
                        return record?.APPLICATION_STATUS && record?.APPLICATION_STATUS == "D"
                      }
                      if(a.actionKey==='Delete'){
                        return record?.APPLICATION_STATUS&&record?.APPLICATION_STATUS=="D"
                      }
                      return true;
                    })
                    .map((a, index) => ({ key: a.label, id: index, label: <MenuActionItem icon={a.icon} label={a.label} /> })),
                  onClick: ({ key }) => {
                    //alert("asd");
                    //console.log("key",key);
                    if (key === "Delete" || key === 'حذف') {
                      if (record?.APPLICATION_STATUS == 'D') {
                        onDelete(record?.APPLID);
                      } else {
                        toast.error(translate("APPLICATION_ALREADY_SUBMITTED"));
                      }
                    } else if (key === "refresh") {
                      refresh();
                    } else if (key === "Offer" || key === 'عرض') {

                      navigate(`/app/${routes.VIEW_OFFER}/${record?.APPLID}`);
                    } else if (key === "Contract" || key === 'View Contract') {

                      navigate(`/app/${routes.VIEW_CONTRACT}/${record?.APPLID}/${record.CONTRACT_STATUS}`);
                    }
                    else if (key === "Edit" || key === "تعديل") {
                      onEdit(record?.APPLID)
                    }
                    else {
                    }
                  },
                }}
              >
                <img alt="" src={MoreVerticalIcon} />
              </Dropdown>
            </div>
          </div>
        ),
      },
    ]
    : columns;

  return <AntTable {...rest} columns={columns} rowKey="APPLID" dataSource={data} />;
}