import c from "classnames";

const ButtonStyle = {
  FILL: "fill",
  OUTLINE: "outline",
};

const ButtonType = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

const primaryFill = "bg-primary text-white";
const primaryOutline = "bg-white border border-primary text-primary";
const secondaryFill = "bg-secondary text-white";
const secondaryOutline = "bg-white border border-secondary text-secondary";

export const PrimaryButton = props => (
  <Button color={ButtonType.PRIMARY}>{props.children}</Button>
);

export const SecondaryButton = (props) => (
  <Button color={ButtonType.SECONDARY}>{props.children}</Button>
);

export function Button({
  block = false,
  className = null,
  color = ButtonType.SECONDARY,
  mode = ButtonStyle.FILL,
  type = 'button',
  onClick,
  disabled = false,
  children,
  ...rest
}) {
  const classNames = c(
    className,
    'rounded py-2',
    'hover:opacity-80',
    'active:opacity-70',
    'disabled:bg-gray-200',
    {
      'block w-full': block,
    },
    (color === ButtonType.PRIMARY && mode === ButtonStyle.FILL && primaryFill),
    (color === ButtonType.PRIMARY && mode === ButtonStyle.OUTLINE && primaryOutline),
    (color === ButtonType.SECONDARY && mode === ButtonStyle.FILL && secondaryFill),
    (color === ButtonType.SECONDARY && mode === ButtonStyle.OUTLINE && secondaryOutline),
  );

  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames}
      disabled={disabled}
      {...rest}
    >{children}</button>
  );
}
