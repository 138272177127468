import { useEffect, useState, useRef } from "react";
import { useTranslation } from "../../contexts/TranslationContext";
import { useNavigate } from "react-router-dom";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import { useGetPersonalInformationMutation } from "services/auth";
import moment from "moment";
import { toast } from "react-toastify";
import { Skeleton, Modal, Button, Input, Row, Col } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-loading-skeleton/dist/skeleton.css";
import { errors } from "shared/constants/errors";
import {
  useCheckPasswordMutation,
  useResetPasswordMutation,
} from "services/auth";

export default function Settings() {
  const [userInfo, setUserInfo] = useState({});
  const [showPasswordFields, setShowPasswordFields] = useState(false); // State to toggle password fields
  const { translate } = useTranslation();
  const [fetchPersonalInfo, { isLoading }] =
    useGetPersonalInformationMutation();
  const [checkPassword, { isLoading: isCheckingPassword }] =
    useCheckPasswordMutation();
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [seconds, setSeconds] = useState(60);
  const [newPassword, setNewPassword] = useState("");

  const otpRef1 = useRef();
  const otpRef2 = useRef();
  const otpRef3 = useRef();
  const otpRef4 = useRef();

  useEffect(() => {
    (async () => {
      let user;
      try {
        user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
      } catch (e) {
        navigate("/auth/sign-in");
        toast.error("Unauthorized. Please sign-in again.");
        return;
      }
      if (!user) {
        navigate("/auth/sign-in");
        return;
      }

      try {
        const response = await fetchPersonalInfo({
          id: String(user?.USER_ID),
        }).unwrap();
        if (response) {
          const info = response?.payload?.data?.info[0];
          const address =
            response?.payload?.data?.address?.personNationalAddressInfo
              ?.Addresses?.[0];
          const dob = moment(info?.DOB, "DD/MM/YYYY", true);
          if (!dob.isValid()) {
            console.error("Invalid date format:", info?.DOB);
            toast.error("Failed to parse date of birth.");
          }
          setUserInfo({
            fullName: `${info?.FIRST_NAME} ${info?.MIDDLE_NAME} ${info?.LAST_NAME}`,
            address: `${address?.BuildingNumber}, ${address?.Street_L2} ${address?.District_L2}`,
            DOB: dob.isValid() ? dob.format("DD/MM/YYYY") : "Invalid Date",
            phone: info?.MOBILE,
          });
        }
      } catch (error) {
        console.log("Error fetching personal info", error);
        toast.error("Failed to load personal information.");
      }
    })();
  }, [fetchPersonalInfo, navigate]);

  const showPasswordModal = () => {
    setIsPasswordModalVisible(true);
    setShowPasswordFields(true);
  };

  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
    setShowPasswordFields(false);
  };

  const handleOtpModalCancel = () => {
    setIsOtpModalVisible(false);
    resetCountdown();
    resetOtp();
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required(errors.CANNOT_BE_EMPTY)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "At least 8 characters, with lowercase, uppercase, digits, and special characters."
      ),
    confirmPassword: Yup.string()
      .required(errors.CANNOT_BE_EMPTY)
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
  });

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
    const phone = user?.MOBILE;
    const otpCode = otp.join("");

    if (otpCode.length < 4) {
      toast.error("Please enter the complete OTP.");
      return;
    }

    try {
      const response = await resetPassword({
        phone: phone,
        otp: otpCode,
        password: newPassword,
      }).unwrap();

      console.log("API response:", response);
      if (response?.passwordUpdate) {
        toast.success("Password reset successfully.");
        resetFormStates();
      } else {
        toast.error(
          response?.message || "Failed to reset password. Please try again."
        );
        setIsOtpModalVisible(false);
        resetOtp();
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error(
        error?.data?.message || "OTP invalid or password reset failed."
      );
      setIsOtpModalVisible(false);
      resetOtp();
    }
  };

  useEffect(() => {
    if (isOtpModalVisible) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          toast.error("Time out try again!");
          clearInterval(intervalId);
          setIsOtpModalVisible(false);
          resetOtp();
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isOtpModalVisible, seconds, translate]);

  const resetCountdown = () => {
    setSeconds(60);
  };

  const resetOtp = () => {
    setOtp(["", "", "", ""]);
  };

  const resetFormStates = () => {
    setIsOtpModalVisible(false);
    setIsPasswordModalVisible(false);
    resetOtp();
    setShowPasswordFields(false);
  };

  const handleChange = (value, index) => {
    if (value.length <= 1) {
      // Ensure only one character is entered
      setOtp((otp) => otp.map((char, i) => (index === i ? value : char)));

      if (index === 0 && otpRef2.current) otpRef2.current.focus();
      if (index === 1 && otpRef3.current) otpRef3.current.focus();
      if (index === 2 && otpRef4.current) otpRef4.current.focus();
    }
  };

  const handlePasswordReset = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await checkPassword({
        password: values.oldPassword,
      }).unwrap();
      if (response.passwordUpdate === false) {
        toast.error("Current password is incorrect.");
        resetForm();
        setSubmitting(false);
        return;
      }
      toast.success("Current password verified.");
      setNewPassword(values.newPassword);
      setIsPasswordModalVisible(false);
      setIsOtpModalVisible(true);
      resetCountdown();
      resetForm();
    } catch (error) {
      toast.error("Invalid current password.");
    }
    setSubmitting(false);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="text-center mb-10">
        <h1 className="text-3xl font-bold text-settingsText">
          {translate("SETTINGS.USER_PROFILE")}
        </h1>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        {isLoading ? (
          <>
            <SkeletonLoader />
          </>
        ) : (
          <>
            <Detail
              label={translate("SETTINGS.Full_Name")}
              value={userInfo.fullName}
            />
            <HorizontalLine />
            <Detail
              label={translate("SETTINGS.Address")}
              value={userInfo.address}
            />
            <HorizontalLine />
            <div className="flex items-center">
              <Detail
                label={translate("SETTINGS.Birth_Date")}
                value={userInfo.DOB}
              />
              <VerticalLine />
              <Detail
                label={translate("SETTINGS.Mobile_Number")}
                value={userInfo.phone}
              />
            </div>
            <HorizontalLine />
          </>
        )}
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 mt-6">
        <Button
          className="font-semibold text-gray-700 mb-1"
          onClick={showPasswordModal}
        >
          {translate("SETTINGS.RESET_PASSWORD")}
        </Button>
      </div>
      <Modal
        title={
          <span className="text-2xl">
            {translate("SETTINGS.RESET_PASSWORD")}
          </span>
        }
        visible={isPasswordModalVisible}
        okType="dashed"
        onCancel={handlePasswordModalCancel}
        footer={null}
      >
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handlePasswordReset}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              {showPasswordFields && (
                <>
                  <div className="my-4">
                    <Field
                      type="password"
                      name="oldPassword"
                      as={Input.Password}
                      placeholder={translate("SETTINGS.OLD_PASSWORD")}
                    />
                    <ErrorMessage
                      name="oldPassword"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="my-4">
                    <Field
                      type="password"
                      name="newPassword"
                      as={Input.Password}
                      placeholder={translate("SETTINGS.NEW_PASSWORD")}
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="my-4">
                    <Field
                      type="password"
                      name="confirmPassword"
                      as={Input.Password}
                      placeholder={translate("SETTINGS.CONFIRM_PASSWORD")}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </>
              )}
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting || isCheckingPassword}
                className="rounded-lg w-full bg-secondary"
              >
                {translate("SETTINGS.SUBMIT")}
              </Button>
              {/* Reset form when modal is cancelled */}
              <Button
                style={{ display: "none" }}
                onClick={() => resetForm()}
                ref={(btn) => btn && !isPasswordModalVisible && btn.click()}
              />
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        title={
          <span className="text-2xl">{translate("SETTINGS.ENTER_OTP")}</span>
        }
        visible={isOtpModalVisible}
        onCancel={handleOtpModalCancel}
        footer={null}
      >
        <div className="text-gray-400 my-4">
          {translate("SETTINGS.WE_HAVE_SENT")}
        </div>
        <form className="space-y-4 md:space-y-6">
          <div>
            <Row gutter={[12, 12]} dir="ltr">
              <Col span={6}>
                <input
                  ref={otpRef1}
                  maxLength="1"
                  onChange={(e) => handleChange(e.target.value, 0)}
                  value={otp[0]}
                  type="text"
                  name="otp1"
                  id="otp1"
                  className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  required
                />
              </Col>
              <Col span={6}>
                <input
                  ref={otpRef2}
                  maxLength="1"
                  onChange={(e) => handleChange(e.target.value, 1)}
                  value={otp[1]}
                  type="text"
                  name="otp2"
                  id="otp2"
                  className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  required
                />
              </Col>
              <Col span={6}>
                <input
                  ref={otpRef3}
                  maxLength="1"
                  onChange={(e) => handleChange(e.target.value, 2)}
                  value={otp[2]}
                  type="text"
                  name="otp3"
                  id="otp3"
                  className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  required
                />
              </Col>
              <Col span={6}>
                <input
                  ref={otpRef4}
                  maxLength="1"
                  onChange={(e) => handleChange(e.target.value, 3)}
                  value={otp[3]}
                  type="text"
                  name="otp4"
                  id="otp4"
                  className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  required
                />
              </Col>
            </Row>
          </div>
          <div className="text-center mt-4">
            <Button
              className="w-full bg-secondary text-white"
              type="submit"
              onClick={handleOtpSubmit}
            >
              {translate("SETTINGS.SUBMIT")} OTP
            </Button>
          </div>
          <div className="font-bold text-3xl text-center text-gray-400">
            00:{String(seconds).padStart(2, "0")}
          </div>
        </form>
      </Modal>
    </div>
  );
}

const Detail = ({ label, value }) => (
  <div className="flex-1">
    <div className="font-semibold text-gray-700 mb-1">{label}</div>
    <div className="text-settingsText font-medium">{value}</div>
  </div>
);

const HorizontalLine = () => <hr className="my-4 border-t border-gray-300" />;

const VerticalLine = () => (
  <div className="w-px bg-gray-300 self-stretch mx-4" />
);

const SkeletonLoader = () => (
  <div>
    <Skeleton paragraph={{ rows: 1 }} width={200} style={{ opacity: 0.7 }} />
    <HorizontalLine />
    <Skeleton paragraph={{ rows: 1 }} width={300} style={{ opacity: 0.7 }} />
    <HorizontalLine />
    <div className="flex items-center">
      <Skeleton paragraph={{ rows: 1 }} width={150} style={{ opacity: 0.7 }} />
      <VerticalLine />
      <Skeleton paragraph={{ rows: 1 }} width={150} style={{ opacity: 0.7 }} />
    </div>
    <HorizontalLine />
  </div>
);
