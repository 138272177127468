import { TranslationContextProvider } from "contexts/TranslationContext";
import AppRouter from "./AppRouter";
import { SessionProvider } from "contexts/SessionContext";

const AppWrapper = () => {
  return (
    <SessionProvider>
      <TranslationContextProvider>
        <AppRouter />
      </TranslationContextProvider>
    </SessionProvider>
  );
};

export default function App() {
  return (
    <AppWrapper />
  );
}
