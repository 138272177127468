import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from 'store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer
      autoClose={1000}
      hideProgressBar={true} />
  </Provider>
);

reportWebVitals();
