import locale from 'antd/lib/date-picker/locale/en_US';

const hijriDatePickerLocale = {
  ...locale,
  lang: {
    ...locale.lang,
    months: [
      "Muharram", "Safar", "Rabi I", "Rabi II", "Jumada I", "Jumada II", "Rajab",
      "Shaban", "Ramadan", "Shawwal", "Zu al-Qa'dah", "Zu al-Hijja"
    ],
    shortMonths: [
      "Muharram", "Safar", "Rabi I", "Rabi II", "Jumada I", "Jumada II", "Rajab",
      "Shaban", "Ramadan", "Shawwal", "Zu al-Qa'dah", "Zu al-Hijja"
    ]
  }
};

export default hijriDatePickerLocale;
