import { DownOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Card, Col, Collapse, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import routes, { appRoute } from "shared/constants/routes";
import { financialDetailSchema, personalDetailsSchema } from "./schema";
import { useGetPersonalInformationMutation, useGetApplicationQuery } from "services/auth";
import useAuthHook from "hooks/useAuthHook";
import moment from "moment-hijri";
import {useTranslation} from "contexts/TranslationContext";

const Field = ({ label, value }) => (
  <>
    <div>{label}</div>
    <div className="font-bold text-md">{value || <>&mdash;</>}</div>
  </>
);

const Fields = ({ fields, values }) => {
    const {translate} = useTranslation();
    return (
        fields.length > 0 ? (
            <Row gutter={[16, 16]}>
                {fields.map((field) => (
                    <Col xs={24} lg={field.span} key={field.key}>
                        <Field label={translate(field.translation)} value={values[field.key]} />
                    </Col>
                ))}
            </Row>
        ) : (
            <div className="text-center">No data found for this segment.</div>
        )
    )
}


const CollapseButton = () => (
  <div className="bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center">
    <DownOutlined />
  </div>
);

export default function ViewRequest() {
  const { id } = useParams();
  const {translate, language} = useTranslation();
  const navigate = useNavigate();
  const [fetchPersonalInfo, { isLoading }] = useGetPersonalInformationMutation();
  const { data: application, isLoading: applicationLoading, refetch } = useGetApplicationQuery(id);
  const [personalInfo, setPersonalInfo] = useState(null);
  const [financialDetails, setFinancialDetails] = useState(null);

  const { getUser } = useAuthHook();

  useEffect(() => {
    if (!id) {
      navigate(routes.HOME);
      toast.error("No ID was specified.");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!application) return;

    setFinancialDetails(
      financialDetailSchema.reduce((acc, row) => {
        const value = application[0][row.key];
        acc[row.key] = value ? `${application[0][row.key]}` : "-";
        return acc;
      }, {})
    );

    setPersonalInfo(
      personalDetailsSchema.reduce((acc, row) => {
        const value = application[0][row.key];
        if (row.key === "DATE_OF_EXPIRY") {
          acc[row.key] = value ? moment(value).format("YYYY-MM-DD") : "-";
        } else if (row.key === "MOBILE") {
          acc[row.key] = value ? `+966 ${value}` : "-";
        } else if (row.key === "GENDER") {
          acc[row.key] = value ? (value === "M" ? "Male" : "Female") : "-";
        } else {
          acc[row.key] = value ? `${value}` : "-";
        }
        return acc;
      }, {})
    );
  }, [application]);

  const items = [
    { label: translate("CREATE_REQUEST.PERSONAL_DETAILS"), schema: personalDetailsSchema, values: personalInfo },
    { label:  translate("CREATE_REQUEST.FINANCIAL_DETAILS"), schema: financialDetailSchema, values: financialDetails },
    /*  
    { label: "Employment details", schema: [], values: {} },
    { label: "Loan details", schema: [], values: {} },
    { label: "Auto loan", schema: [], values: {} },
    { label: "Document", schema: [], values: {} },
    { label: "Guarantors", schema: [], values: {} }, */
  ];

  return (
    <section>
      <Card>
        <div className="flex items-center gap-4 border-b-gray border-b-2 pb-3 mb-3">
          <div onClick={() => navigate(appRoute(routes.HOME))} className="border  border-gray-200 cursor-pointer w-10 h-10 rounded-lg flex items-center justify-center">
              { language.code === 'ar' ? <RightOutlined/> : <LeftOutlined />}
          </div>
            <h2 className="font-semibold text-lg">{translate('NAV.HOME')}</h2>
        </div>
          <h1 className="font-bold text-2xl"> {translate('REQUEST_DETAILS.DETAILS')}  - {id} -  {translate('REQUEST_DETAILS.REQUEST_NUMBER')}  #{application?.[0].KASTLE_APPL_ID || '-'}</h1>

          {isLoading && <p>Loading...</p>}
        {personalInfo && financialDetails && (
          <Collapse
            size="large"
            bordered={false}
            defaultActiveKey={0}
            items={items.map((item, i) => ({
              key: i,
              label: item.label,
              showArrow: false,
              extra: <CollapseButton />,
              style: {
                backgroundColor: "white",
              },
              children: <Fields fields={item.schema} values={item.values} />,
            }))}
          />
        )}
      </Card>
    </section>
  );
}
