import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "shared/constants/env";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: env.API_ENDPOINT }),
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: { iqamaId:credentials.iqamaId},
        headers:{
          credential: btoa(credentials.password)
        }
      }),
    }),
    verify: builder.mutation({
      query: (credentials) => ({
        url: "auth/verify",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "auth/forgot-password",
        method: "POST",
        body,
      }),
    }),
    forgotPasswordVerification: builder.mutation({
      query: (body) => ({
        url: "auth/forgot-password-verify",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "auth/verify-reset-password",
        method: "POST",
        body,
      }),
    }),
    validateId: builder.mutation({
      query: (body) => ({
        url: "auth/validate-id",
        method: "POST",
        body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "auth/register",
        method: "POST",
        body,
      }),
    }),
    getStatus: builder.mutation({
      query: (body) => ({
        url: "auth/get-status",
        method: "POST",
        body,
      }),
    }),
    CheckPassword: builder.mutation({
      query: (body) => ({
        url: "app/reset-password",
        method: "POST",
        body,
      }),
    }),
    validatePhone: builder.mutation({
      query: (body) => ({
        url: "auth/validate-tahqoq-info",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: "auth/logout",
        method: "POST",
        body,
      }),
    }),
    getPersonalInformation: builder.mutation({
      query: (body) => ({
        url: "app/personal-info",
        method: "POST",
        body,
      }),
    }),
    getApplication: builder.query({
      query: (appID) => ({ url: `app/user-app?appId=${appID}` }),
      transformResponse: (response) => response?.payload?.rows || [],
      transformErrorResponse: (response) => ({
        error: "FAILED_TO_LOAD_APP",
      }),
      keepUnusedDataFor:0  //for not caching data
    }),
    createNewApp: builder.mutation({
      query: (body) => ({
        url: "app/insert-app",
        method: "POST",
        body,
      }),
    }),
    getUserApps: builder.query({
      query: (idbUserId) => ({ url: `app/user-apps?idbUserId=${idbUserId}` }),
      transformResponse: (response) => response?.payload?.rows || [],
      transformErrorResponse: (response) => ({
        error: "FAILED_TO_LOAD_APPS",
      }),
    }),
    updateAppDetails: builder.mutation({
      query: (body) => ({
        url: "app/update-details-application",
        method: "PUT",
        body,
      }),
    }),
    updateCustomerDetails: builder.mutation({
      query: (body) => ({
        url: "app/update-details-cust",
        method: "PUT",
        body,
      }),
    }),
    getApplicationOffer: builder.query({
      query: (applId) => ({ url: `app/get-offer?appId=${applId}` }),
      transformResponse: (response) => (response?.payload?.length > 0 ? response?.payload[0] : null),
      transformErrorResponse: (response) => ({
        error: "FAILED_TO_LOAD_OFFER",
      }),
    }),
    updateAppOffer: builder.mutation({
      query: (body) => ({
        url: "app/update-offer",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { useSignInMutation, useVerifyMutation, useForgotPasswordMutation, useForgotPasswordVerificationMutation, useResetPasswordMutation, useValidateIdMutation, useRegisterMutation, useGetStatusMutation, useValidatePhoneMutation, useLogoutMutation, useGetPersonalInformationMutation, useCreateNewAppMutation, useGetUserAppsQuery, useGetApplicationQuery, useUpdateAppDetailsMutation, useUpdateCustomerDetailsMutation, useGetApplicationOfferQuery, useUpdateAppOfferMutation,useCheckPasswordMutation } = api;
