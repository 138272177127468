import { Modal } from "antd";
import { API_URL } from "constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateAppOfferMutation } from "services/auth";
import { Button } from "shared/components/Button";
import routes, { appRoute } from "shared/constants/routes";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import env from "shared/constants/env";

export default function AcceptModal({ appId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleConfirm = async () => {
    //setIsOpen(false);
    //navigate(appRoute(routes.HOME));

    try {
      setLoading(true);
      const response = await fetch(`${env.API_ENDPOINT}app/customer-contract-decision`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerStatus: 'ACCEPTED',
          appId: `${appId}`,
        }),
      });
      const data = await response.json();

      toast.success('Contract Accepted');
    } catch (ex) {
      console.log("Exception AcceptModal", ex);
      toast.error("Unable to accept Contract, please try again");
    } finally{
      setIsOpen(false);
      setLoading(false);
      navigate(appRoute(routes.HOME));
    }
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} color="primary" className="rounded-xl h-12 w-32">
        Accept
      </Button>
      <Modal
        open={isOpen}
        title="Confirm?"
        onCancel={() => setIsOpen(false)}
        footer={
          <Button disabled={loading} onClick={handleConfirm} color="primary" className="rounded-xl min-w-32">
            {loading ? <LoadingOutlined /> : "Confirm"}
          </Button>
        }
      >
        Confirm Contract Acceptance?
      </Modal>
    </>
  );
}
