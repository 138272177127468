import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "contexts/TranslationContext";
import { useNavigate } from "react-router-dom";
import { useUpdateAppDetailsMutation, useUpdateCustomerDetailsMutation } from "services/auth";
import { Button } from "shared/components/Button";
import { createRequestRoute } from "shared/constants/routes";
import CancelModal from "../CancelModal";
import { MOVE_NEXT, PATCH_AUTO_LOAN_DETAILS, SET_ACTIVE_STEP_INDEX, useCreateRequestContext, } from "../CreateRequestContext";
import { toast } from "react-toastify";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import { API_URL } from "constants";
import env from "shared/constants/env";
import axios from "axios";

const APP_DETAILS_DTO_KEYS = ["IBAN", "gender", "maritalStatus", "nationality", "numberOfDependents", "title", "region", "city", "foodExpenses", "housingExpenses", "domesticWorkers", "educationExpenses", "healthCareExpenses", "commAndTransExpenses", "insuranceExpenses", "anyExpectedFutureExpense", "productId", "subproductId", "request_amount", "assetModelYear", "assetPrice", "downPayment", "ballonPayment", "dealerId", "colorId", "assetId", "salesRef", "tenure", "empCatId", "p_residentId", "qualificationId", "sectorId", "dateOfJoining", "lat", "long", "buildingNum", "streetName", "district_name", "additionalNum", "postCode"];

const filterObjectByKeys = (obj, keys) =>
	Object.keys(obj)
		.filter((key) => keys.includes(key))
		.reduce((data, key) => ({ ...data, [key]: obj[key] }), {});

export const StepperControls = () => {
	const navigate = useNavigate();
	const { state, dispatch } = useCreateRequestContext();
	const { translate } = useTranslation();
	const [updateApplicationDetails, { isLoading }] = useUpdateAppDetailsMutation();
	const [updateCustomerDetails] = useUpdateCustomerDetailsMutation();

	// Sales Representative Reference (Guarantor)
	const isLastStep = state?.activeStepIndex === 6;

	return (
		<div className='flex items-center justify-end gap-2'>
			{state?.activeStepIndex !== 7 && <CancelModal />}
			{state?.activeStepIndex !== 7 && (
				<Button
					onClick={async () => {
						let userWebId;
						try {
							userWebId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))?.IDB_USR_ID;
						} catch (e) {
							toast.error("You have been logged out.");
							navigate("/auth/sign-in");
						}

						if (state.activeStepIndex == 5 && state.documentDetails.length) {
							state.documentDetails.map((file) => {
								const formData = new FormData();
								formData.append("document", file);
								formData.append("appId", state.appId);
								const data = JSON.parse(localStorage.getItem("@@TAMWEEL_USER"));

								axios
									.post(`${env.API_ENDPOINT}app/upload-document`, formData, {
										headers: {
											Authorization: `Bearer ${data.token}`,
										},
									})
									.then((res) => {
										console.log("here", res);
									})
									.catch(() => {
										toast.error("Failed to upload file.");
									});
							});
						}

						//console.log('personalDetails',state.personalDetails);
						//console.log('employmentDetails',state.employmentDetails);
						if (state.activeStepIndex === 3 && !state?.loanDetails?.isAutofacility) {
							dispatch({
								type: PATCH_AUTO_LOAN_DETAILS,
								payload: {
									assetManufacturer: null,
									assetModel: null,
									assetModelType: null,
									assetColor: null,
									assetCondition: null,
									assetPrice: null,
									dealerName: null,
								},
							});
						}
						try {
							const details = {
								...filterObjectByKeys(
									{
										...state.personalDetails,
										IBAN: state.personalDetails?.IBAN ? "SA" + state.personalDetails?.IBAN : null,
										...state.financialDetails,
										...state.employmentDetails,
										...state.loanDetails,
										productId: state.loanDetails.productId ? JSON.parse(state.loanDetails.productId).PRODUCTID : null,
										...(state.activeStepIndex === 3 && !state?.loanDetails?.isAutofacility ? {} : state.autoLoanDetails),
										...state.salesPersonRefDetails,
									},
									APP_DETAILS_DTO_KEYS
								),
								applStatus: isLastStep ? "S" : "D",
							};
							console.log("details", details);
							const response = await updateApplicationDetails({
								appId: state.appId,
								userWebId: userWebId,
								details: details,
							});
							//console.log("response", response);
							if (response.error) {
								throw new Error(response.error);
							}
							const nextStep = state.activeStepIndex + 1;
							const skipToNextStep = state.activeStepIndex + 2;

							if (state.activeStepIndex === 3) {
								// Loan Details
								if (state?.loanDetails?.isTawarooq) {
									dispatch({
										type: SET_ACTIVE_STEP_INDEX,
										payload: skipToNextStep,
									});
									navigate(createRequestRoute(state.steps[skipToNextStep].path) + `?appl_=${state.appId}`);
								}

								if (!state?.loanDetails?.isAutofacility) {
									//if not auto loan then skip
									dispatch({
										type: SET_ACTIVE_STEP_INDEX,
										payload: skipToNextStep,
									});
									navigate(createRequestRoute(state.steps[skipToNextStep].path) + `?appl_=${state.appId}`);
								} else {
									dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: nextStep });
									navigate(createRequestRoute(state.steps[nextStep].path) + `?appl_=${state.appId}`);
								}
							} else {
								dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: nextStep });
								navigate(createRequestRoute(state.steps[nextStep].path) + `?appl_=${state.appId}`);
							}
						} catch (ex) {
							toast.error(translate("Something went wrong"));
							//console.log("Exception in Stepper", ex);
						}
					}}
					disabled={!state.steps[state.activeStepIndex]?.valid || isLoading}
					color='primary'
					className='rounded-xl h-12 w-64'>
					{isLoading ? <LoadingOutlined /> : translate("CREATE_REQUEST.NEXT")}
				</Button>
			)}

		</div>
	);
};
