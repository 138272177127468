import {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

const SessionContext = createContext({});

export function SessionProvider({ children }) {
  const [session, setSession] = useState(null);

  const signIn = useCallback((session) => {
    setSession(session);
  }, [setSession]);

  const signOut = useCallback(() => {
    setSession(null);
  }, [setSession]);

  return (
    <SessionContext.Provider value={{
      session,
      signIn,
      signOut,
    }}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error(
      'useSession must be used within <SessionProvider />.'
    );
  }

  return context;
}
