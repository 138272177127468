import { useNavigate } from 'react-router-dom';
import * as LOCAL_STORAGE_KEYS from 'shared/constants/localStorageKeys';
import routes from 'shared/constants/routes';

export default function useAuthHook() {
  const navigate = useNavigate();

  const getUser = () => {
    try {
      const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);

      if (!user) {
        logout();
        return;
      }

      return JSON.parse(user);
    } catch (e) {
      logout();
    }
  };

  const logout = () => {
    navigate(routes.AUTH.SIGN_IN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
  };

  return {
    getUser,
    logout,
  };
}
