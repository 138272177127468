import { createContext, useCallback, useContext, useState } from 'react';

import ar from '../i18n/ar';
import en from '../i18n/en';

const locales = { ar, en };

export const LANGUAGES = {
  en: {
    dir: 'ltr',
    name: 'English',
    code: 'en',
    shortname: 'EN',
  },
  ar: {
    dir: 'rtl',
    name: 'العربية',
    code: 'ar',
    shortname: 'AR',
  },
};

const TranslationContext = createContext({
  language: LANGUAGES?.ar,
  setLanguage: (language) => {},
  translate: string => string,
});

const resolvePath = (bucket, path) => path.split('.').reduce(
  (obj, key) => obj[key],
  bucket
);

export const TranslationContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(LANGUAGES?.ar);

  const translate = useCallback((string) => {
    try {
      return resolvePath(locales[language?.code], string) || string;
    } catch (e) {
      return string;
    }
  }, [language]);

  return (
    <TranslationContext.Provider value={{
      language,
      setLanguage,
      translate
    }}>{children}</TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);

  if (!context) {
    throw new Error('Called outside <TranslationContextProvider>.');
  }

  return context;
};