import { useGetApplicationQuery } from "services/auth";
import { useCreateRequestContext, SET_EDIT_APPL_LOAD_FLAGS, PATCH_PERSONAL_DETAILS, PATCH_EMPLOYMENT_DETAILS, PATCH_FINANCIAL_DETAILS, PATCH_LOAN_DETAILS, PATCH_AUTO_LOAN_DETAILS } from "./CreateRequestContext";
import { useEffect } from "react";
import { toast } from "react-toastify";
import env from 'shared/constants/env';


const useGetApp = (applId) => {
    const { state, dispatch } = useCreateRequestContext();
    const skipQuery = !applId;
    const { data: applicationData, isLoading, error, refetch } = useGetApplicationQuery(applId, {
        skip: skipQuery, // Skip the query if appId is not available
        refetchOnMountOrArgChange: true
    });

    const fetchProducts = async (productId) => {
        try {
            const response = await fetch(`${env.API_ENDPOINT}lookup/products?status=A`);
            const data = await response.json();
            if (data) {
                return data.payload.rows.find(product => product.PRODUCTID === parseInt(productId));
            }
        }
        catch (error) {
            toast.error("Error processing Application data");
        }
        return null
    }
    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const mapGetAppResponseToFormValues = (getAppResponse) => {
        const mappings = {
            personalDetails: {
                IBAN: getAppResponse.IBAN ? getAppResponse.IBAN.slice(2) : '',
                gender: getAppResponse.GENDER || '',
                maritalStatus: getAppResponse.MARSTATID ? String(getAppResponse.MARSTATID) : '',
                nationality: getAppResponse.NATIONALITYID ? String(getAppResponse.NATIONALITYID) : '',
                numberOfDependents: getAppResponse.FAMILYSIZEID ? String(getAppResponse.FAMILYSIZEID) : '',
                title: getAppResponse.TITLE_ID ? String(getAppResponse.TITLE_ID) : '',
                region: getAppResponse.REGIONID ? String(getAppResponse.REGIONID) : '',
                city: getAppResponse.CITYID ? String(getAppResponse.CITYID) : '',
                lat: getAppResponse.LATITUDE != null ? String(getAppResponse.LATITUDE) : '',
                long: getAppResponse.LONGITUDE != null ? String(getAppResponse.LONGITUDE) : '',
                buildingNum: getAppResponse.BUILDINGNO || '',
                streetName: getAppResponse.STREETNAME || '',
                district_name: getAppResponse.DISTRICT_NAME || '',
                additionalNum: getAppResponse.ADDITIONALNO || '',
                postCode: getAppResponse.POSTCODE || ''
            },
            employmentDetails: {
                empCatId: getAppResponse.EMPCATID ? String(getAppResponse.EMPCATID) : '',
                p_residentId: getAppResponse.RESIDENTID ? String(getAppResponse.RESIDENTID) : '',
                qualificationId: getAppResponse.QUALIFICATIONID ? String(getAppResponse.QUALIFICATIONID) : '',
                sectorId: getAppResponse.SECTORID ? String(getAppResponse.SECTORID) : '',
                dateOfJoining: getAppResponse.DATEOFJOINING ? formatDate(getAppResponse.DATEOFJOINING) : ''
            },
            financialDetails: {
                foodExpenses: getAppResponse.FOOD_EXPENSES ? getAppResponse.FOOD_EXPENSES : 0,
                housingExpenses: getAppResponse.HOUSING_EXPENSES ? getAppResponse.HOUSING_EXPENSES : 0,
                domesticWorkers: getAppResponse.DOMESTIC_WORKERS ? getAppResponse.DOMESTIC_WORKERS : 0,
                educationExpenses: getAppResponse.EDUCATION_EXPENSES ? getAppResponse.EDUCATION_EXPENSES : 0,
                healthCareExpenses: getAppResponse.HEALTHCARE_EXPENSES ? getAppResponse.HEALTHCARE_EXPENSES : 0,
                commAndTransExpenses: getAppResponse.COMM_TRANS_EXPENSES ? getAppResponse.COMM_TRANS_EXPENSES : 0,
                insuranceExpenses: getAppResponse.INSURANCE_EXPENSES ? getAppResponse.INSURANCE_EXPENSES : 0,
                anyExpectedFutureExpense: getAppResponse.FUTURE_EXPENSES ? getAppResponse.FUTURE_EXPENSES : 0,
                totalExpenses: getAppResponse.TOTAL_EXPENSES ? String(getAppResponse.TOTAL_EXPENSES) : ''
            },
            loanDetails: {
                productId: getAppResponse.PRODUCTID ? String(getAppResponse.PRODUCTID) : '',
                subproductId: getAppResponse.SUBPRODUCTID ? String(getAppResponse.SUBPRODUCTID) : '',
                request_amount: getAppResponse.REQUESTED_AMOUNT != null ? getAppResponse.REQUESTED_AMOUNT : '',
                tenure: getAppResponse.TENURE != null ? String(getAppResponse.TENURE) : ''
            },
            autoLoanDetails: {
                assetModelYear: getAppResponse.ASSET_MODEL_YEAR ? String(getAppResponse.ASSET_MODEL_YEAR) : '',
                assetPrice: getAppResponse.ASSET_PRICE != null ? String(getAppResponse.ASSET_PRICE) : '',
                downPayment: getAppResponse.DOWN_PAYMENT != null ? String(getAppResponse.DOWN_PAYMENT) : '',
                ballonPayment: getAppResponse.BALLOON_PAYMENT != null ? String(getAppResponse.BALLOON_PAYMENT) : '',
                dealerId: getAppResponse.DEALERID ? String(getAppResponse.DEALERID) : '',
                colorId: getAppResponse.COLORID ? String(getAppResponse.COLORID) : '',
                assetId: getAppResponse.ASSETID ? String(getAppResponse.ASSETID) : ''
            },
            salesDetails: {
                salesRef: getAppResponse.SALES_REF ? String(getAppResponse.SALES_REF) : ''
            }
        };

        const filterMappings = (mappings) => {
            return Object.entries(mappings).reduce((result, [sectionKey, sectionValues]) => {
                const filteredSection = Object.entries(sectionValues).reduce((section, [key, value]) => {
                    if (value !== '' && value != null) {
                        section[key] = value;
                    }
                    return section;
                }, {});

                if (Object.keys(filteredSection).length > 0) {
                    result[sectionKey] = filteredSection;
                }

                return result;
            }, {});
        }

        const result = filterMappings(mappings)
        return result;
    }

    useEffect(() => {
        const handleDispatch = async () => {
            if (applicationData && !skipQuery) {
                try {
                    const formData = mapGetAppResponseToFormValues(applicationData[0]);
                    console.log('GetAppData:', formData);

                    if (formData.personalDetails) {
                        dispatch({
                            type: PATCH_PERSONAL_DETAILS,
                            payload: formData.personalDetails
                        });

                        // initiliazing EditApplLoad flags
                        dispatch({
                            type: SET_EDIT_APPL_LOAD_FLAGS,
                            payload: {
                                personalDetails: false,
                                employmentDetails: false,
                                financialDetails: false,
                                loanDetails: false,
                                autoLoanDetails: false,
                                documentDetails: false,
                                salesPersonRefDetails: false
                            }
                        });
                    }
                    if (formData.employmentDetails) {
                        dispatch({
                            type: PATCH_EMPLOYMENT_DETAILS,
                            payload: formData.employmentDetails
                        });
                    }
                    if (formData.financialDetails) {
                        dispatch({
                            type: PATCH_FINANCIAL_DETAILS,
                            payload: formData.financialDetails
                        });
                    }
                    if (formData.loanDetails) {
                        const foundProduct = await fetchProducts(formData.loanDetails.productId);
                        dispatch({
                            type: PATCH_LOAN_DETAILS,
                            payload: {
                                ...formData.loanDetails,
                                productId: foundProduct ? JSON.stringify(foundProduct) : null
                            }
                        });
                    }
                    if (formData.autoLoanDetails) {
                        dispatch({
                            type: PATCH_AUTO_LOAN_DETAILS,
                            payload: formData.autoLoanDetails
                        });
                    }
                } catch (ex) {
                    toast.error("Error processing Application data");
                }
            }
        }
        handleDispatch()
    }, [applicationData, dispatch]);

    return { isLoading, error, refetch };
}

export { useGetApp }