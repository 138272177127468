/* export const personalDetailsSchema = [
  { key: "fullName", label: "Full name", span: 24 },
  { key: "maritalStatus", label: "Marital status", span: 12 },
  { key: "numberOfDependents", label: "Number of dependents", span: 12 },
  { key: "nationality", label: "Nationality", span: 24 },
  { key: "address", label: "Address", span: 24 },
  { key: "postCode", label: "Post code", span: 12 },
  { key: "gender", label: "Gender", span: 12 },
  { key: "idIssuePlace", label: "ID issue place", span: 12 },
  { key: "idIssueDate", label: "ID issue date", span: 12 },
]; */
export const personalDetailsSchema = [
  { key: "CUSTOMERNAME", label: "Full name", span: 12, translation: 'REQUEST_DETAILS.CUSTOMERNAME' },
  { key: "EMAIL", label: "Email", span: 12, translation: 'REQUEST_DETAILS.EMAIL' },
  { key: "IDNUMBER", label: "Iqama Number", span: 12, translation: 'REQUEST_DETAILS.IDNUMBER' },
  { key: "DATE_OF_EXPIRY", label: "Expiry Date", span: 12, translation: 'REQUEST_DETAILS.DATE_OF_EXPIRY' },
  { key: "IBAN", label: "IBAN", span: 24, translation: 'REQUEST_DETAILS.IBAN' },
  { key: "GENDER", label: "Gender", span: 12, translation: 'REQUEST_DETAILS.GENDER' },
  { key: "MOBILE", label: "Mobile No.", span: 12, translation: 'REQUEST_DETAILS.MOBILE' },
];

export const financialDetailSchema = [
  { key: "FOOD_EXPENSES", label: "Food Expenses", span: 12, translation: 'REQUEST_DETAILS.FOOD_EXPENSES' },
  { key: "HOUSING_EXPENSES", label: "Housing expenses", span: 12, translation: 'REQUEST_DETAILS.HOUSING_EXPENSES' },
  { key: "DOMESTIC_WORKERS", label: "Domestic workers", span: 12, translation: 'REQUEST_DETAILS.DOMESTIC_WORKERS' },
  { key: "EDUCATION_EXPENSES", label: "Education expenses", span: 12, translation: 'REQUEST_DETAILS.EDUCATION_EXPENSES' },
  { key: "HEALTHCARE_EXPENSES", label: "Health care expenses", span: 12, translation: 'REQUEST_DETAILS.HEALTHCARE_EXPENSES' },
  { key: "COMM_TRANS_EXPENSES", label: "Communication and transportation expenses", span: 12, translation: 'REQUEST_DETAILS.COMM_TRANS_EXPENSES' },
  { key: "INSURANCE_EXPENSES", label: "Insurance expenses", span: 12, translation: 'REQUEST_DETAILS.INSURANCE_EXPENSES' },
  { key: "FUTURE_EXPENSES", label: "Any expected future expenses", span: 12, translation: 'REQUEST_DETAILS.FUTURE_EXPENSES' },
];

export const employementDetailSchema = [
  { key: "EMPCATID", label: "Employment type", span: 24, translation: 'REQUEST_DETAILS.EMPCATID' },
  { key: "SECTORID", label: "Employment Sector", span: 24, translation: 'REQUEST_DETAILS.SECTORID' },
  { key: "DATEOFJOINING", label: "Date of Joining", span: 24, translation: 'REQUEST_DETAILS.DATEOFJOINING' },
  { key: "QUALIFICATIONID", label: "Education Level", span: 24, translation: 'REQUEST_DETAILS.QUALIFICATIONID' },
  { key: "RESIDENTID", label: "Resident Status", span: 24, translation: 'REQUEST_DETAILS.RESIDENTID' },
];

export const loanDetailSchema = [
  { key: "PRODUCTID", label: "Type of loan", span: 24, translation: 'REQUEST_DETAILS.PRODUCTID' },
  { key: "SUBPRODUCTID", label: "Facility", span: 24, translation: 'REQUEST_DETAILS.SUBPRODUCTID' },
  { key: "REQUESTED_AMOUNT", label: "Requested loan amount", span: 24, translation: 'REQUEST_DETAILS.REQUESTED_AMOUNT' },
  { key: "TENURE", label: "Tenure (in months)", span: 24, translation: 'REQUEST_DETAILS.TENURE' },
];

export const autoLoanSchema = [
  { key: "", label: "Asset manufacturer", span: 24, translation: 'REQUEST_DETAILS.ASSET_MANUFACTURER' },
  { key: "", label: "Asset model", span: 24, translation: 'REQUEST_DETAILS.ASSET_MODEL' },
  { key: "", label: "Asset model type", span: 24, translation: 'REQUEST_DETAILS.ASSET_MODEL_TYPE' },
  { key: "COLORID", label: "Asset color", span: 24, translation: 'REQUEST_DETAILS.COLORID' },
  { key: "", label: "Asset condition", span: 24, translation: 'REQUEST_DETAILS.ASSET_CONDITION' },
  { key: "ASSET_PRICE", label: "Asset price", span: 24, translation: 'REQUEST_DETAILS.ASSET_PRICE' },
  { key: "ASSET_MODEL_YEAR", label: "Asset model year", span: 24, translation: 'REQUEST_DETAILS.ASSET_MODEL_YEAR' },
  { key: "DOWN_PAYMENT", label: "Down payment", span: 24, translation: 'REQUEST_DETAILS.DOWN_PAYMENT' },
  { key: "BALLOON_PAYMENT", label: "Balloon payment", span: 24, translation: 'REQUEST_DETAILS.BALLOON_PAYMENT' },
  { key: "DEALERID", label: "Dealer name", span: 24, translation: 'REQUEST_DETAILS.DEALERID' },
];

export const documentSchema = [];

export const guarantorSchema = [];
