import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AccessDenied from "pages/AccessDenied";
import ForgotPassword from "pages/ForgotPassword";
import NotFound from "pages/NotFound";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import CreateRequest from "pages/protected/CreateRequest/CreateRequest";
import EmploymentDetails from "pages/protected/CreateRequest/EmploymentDetails";
import LoanDetails from "pages/protected/CreateRequest/LoanDetails";
import PersonalDetails from "pages/protected/CreateRequest/PersonalDetails";
import Home from "pages/protected/Home";

import routes from "shared/constants/routes";
import Document from "pages/protected/CreateRequest/Document";
import Guarantors from "pages/protected/CreateRequest/Guarantors";
import Success from "pages/protected/CreateRequest/Success";
import ViewRequest from "pages/protected/ViewRequest/ViewRequest";
import ViewOffer from "pages/protected/ViewOffer/ViewOffer";
import Layout from "shared/views/Layout/Layout";
import FinancialDetails from "pages/protected/CreateRequest/FinancialDetails";
import AutoLoan from "pages/protected/CreateRequest/AutoLoan";
import { Components } from "./Components";
import { useTranslation } from "contexts/TranslationContext";
import { useEffect, useLayoutEffect } from "react";
import { ProtectedResource } from "shared/components/ProtectedResource";
import ViewContract from "pages/protected/ViewContract/ViewContract";
import Settings from "pages/protected/Settings"; 



export default function AppRouter() {
  const { language } = useTranslation();


  useLayoutEffect(() => {
    window.document.dir = language?.dir;
  }, [language?.dir]);



  return (
    <BrowserRouter>
      <Routes>
        <Route path="/components" element={<Components />} />
        <Route path="/" element={<Navigate to={routes.AUTH.SIGN_IN} />} />
        <Route path={routes.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={routes.AUTH.SIGN_IN} element={<SignIn />} />
        <Route path={routes.AUTH.SIGN_UP} element={<SignUp />} />

        <Route
          path={`/${routes.APP_ROOT}`}
          element={
            <ProtectedResource>
              <Layout />
            </ProtectedResource>
          }
        >
          <Route path="" element={<Navigate to={routes.HOME} />} />
          <Route path={routes.HOME} element={<Home />} />
      
          <Route path={routes.SETTINGS} element={<Settings />} />
          <Route path={routes.CREATE_REQUEST} element={<CreateRequest />}>
            <Route path="" element={<Navigate to={routes.CREATE_REQUEST_PERSONAL_DETAILS} />} />
            <Route path={routes.CREATE_REQUEST_PERSONAL_DETAILS} element={<PersonalDetails />} />
            <Route path={routes.CREATE_REQUEST_EMPLOYMENT_DETAILS} element={<EmploymentDetails />} />
            <Route path={routes.CREATE_REQUEST_FINANCIAL_DETAILS} element={<FinancialDetails />} />
            <Route path={routes.CREATE_REQUEST_LOAN_DETAILS} element={<LoanDetails />} />
            <Route path={routes.CREATE_REQUEST_AUTO_LOAN} element={<AutoLoan />} />
            <Route path={routes.CREATE_REQUEST_DOCUMENT_DETAILS} element={<Document />} />
            <Route path={routes.CREATE_REQUEST_GUARANTORS_DETAILS} element={<Guarantors />} />
            <Route path={routes.CREATE_REQUEST_SUCCESS} element={<Success />} />
          </Route>
          <Route path={routes.VIEW_REQUEST + "/:id"} element={<ViewRequest />} />
          <Route path={routes.VIEW_OFFER + "/:appId"} element={<ViewOffer />} />
          <Route path={routes.VIEW_CONTRACT + "/:appId/:contractStatus"} element={<ViewContract />} />
        </Route>

        <Route path={routes.ACCESS_DENIED} element={<AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
