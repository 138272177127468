import { createContext, useContext, useReducer } from 'react';
import routes from 'shared/constants/routes';

export const CreateRequestContext = createContext(null);

export const FLUSH_STORE = 'FLUSH_STORE';
export const MOVE_NEXT = 'MOVE_NEXT';
export const MOVE_PREVIOUS = 'MOVE_PREVIOUS';

export const PATCH_PERSONAL_DETAILS = 'PATCH_PERSONAL_DETAILS';
export const PATCH_EMPLOYMENT_DETAILS = 'PATCH_EMPLOYMENT_DETAILS';
export const PATCH_LOAN_DETAILS = 'PATCH_LOAN_DETAILS';
export const PATCH_FINANCIAL_DETAILS = 'PATCH_FINANCIAL_DETAILS';
export const PATCH_AUTO_LOAN_DETAILS = 'PATCH_AUTO_LOAN_DETAILS';
export const PATCH_GUARANTOR_DETAILS = 'PATCH_GUARANTOR_DETAILS';
export const PATCH_DOCUMENT_DETAILS = 'PATCH_DOCUMENT_DETAILS';

export const SET_ACTIVE_STEP_INDEX = 'SET_ACTIVE_STEP_INDEX';

export const SET_STEP_VALIDITY = 'SET_STEP_VALIDITY';

export const SET_APP_ID = 'SET_APP_ID';
export const SET_EDIT_APPL_LOAD_FLAGS = 'SET_EDIT_APPL_LOAD_FLAGS';

const INITIAL_STATE = {
  appId: null,
  activeStepIndex: 0,
  editApplLoadFlags: null,
  personalDetails: {
    fullName: null,
    maritalStatus: null,
    dependents: null,
    nationality: null,
    city: null,
    postCode: null,
    idExpiryDate: null,
    IBAN: null,
    gender: null,
    lat: 0,
    long: 0,
    region: null,
    title: null,
    buildingNum: null,
    streetName: null,
    district_name: null,
    additionalNum: null,
  },
  employmentDetails: {
    employmentType: null,
    qualificationId: null,
    p_residentId: null,
    sectorId: null,
    dateOfJoining: null,
  },
  financialDetails: {
    foodExpenses: null,
    housingExpenses: null,
    domesticWorkers: null,
    educationExpenses: null,
    healthCareExpenses: null,
    communicationAndTransportationExpenses: null,
    insuranceExpenses: null,
    anyExpectedFutureExpense: null,
    totalExpenses: null,
  },
  loanDetails: {
    typeOfLoan: null,
    facility: null,
    requestedLoanAmount: null,
    tenure: null,
  },
  autoLoanDetails: {
    assetManufacturer: null,
    assetModel: null,
    assetModelType: null,
    assetColor: null,
    assetCondition: null,
    assetPrice: null,
    dealerName: null,
  },
  documentDetails: {},
  salesPersonRefDetails: {},
  steps: [
    { title: 'Personal Details', path: routes.CREATE_REQUEST_PERSONAL_DETAILS, valid: false, },
    { title: 'Employment Details', path: routes.CREATE_REQUEST_EMPLOYMENT_DETAILS, valid: false, },
    { title: 'Financial Details', path: routes.CREATE_REQUEST_FINANCIAL_DETAILS, valid: false, },
    { title: 'Loan Details', path: routes.CREATE_REQUEST_LOAN_DETAILS, valid: false, },
    { title: 'Auto Loan Details', path: routes.CREATE_REQUEST_AUTO_LOAN, valid: false, },
    { title: 'Document Details', path: routes.CREATE_REQUEST_DOCUMENT_DETAILS, valid: true, },
    { title: 'Guarantors Details', path: routes.CREATE_REQUEST_GUARANTORS_DETAILS, valid: false, },
    { title: 'Finish', path: routes.CREATE_REQUEST_SUCCESS, valid: false, },
  ],
};

const createRequestReducer = (state, action) => {
  switch (action.type) {
    case SET_EDIT_APPL_LOAD_FLAGS:
      return {
        ...state,
        editApplLoadFlags: action.payload,
      };
    case SET_APP_ID:
      console.log("appId", action)
      return {
        ...state,
        appId: action.payload,
      };
    case SET_STEP_VALIDITY:
      return {
        ...state,
        steps: state.steps.map((step, stepIndex) => {
          if (stepIndex === action?.payload?.stepIndex) {
            return {
              ...step,
              valid: action?.payload?.valid,
            };
          } else {
            return step;
          }
        }),
      };
    case FLUSH_STORE:
      return INITIAL_STATE;
    case MOVE_NEXT:
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
      };
    case MOVE_PREVIOUS:
      return {
        ...state,
        activeStepIndex: state.activeStepIndex - 1,
      };
    case SET_ACTIVE_STEP_INDEX:
      return {
        ...state,
        activeStepIndex: action.payload,
      };
    case PATCH_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: { ...action.payload },
      };
    case PATCH_EMPLOYMENT_DETAILS:
      return {
        ...state,
        employmentDetails: action.payload,
      };
    case PATCH_FINANCIAL_DETAILS:
      return {
        ...state,
        financialDetails: action.payload,
      };
    case PATCH_LOAN_DETAILS:
      return {
        ...state,
        loanDetails: action.payload,
      };
    case PATCH_AUTO_LOAN_DETAILS:
      return {
        ...state,
        autoLoanDetails: action.payload,
      };
    case PATCH_GUARANTOR_DETAILS:
      return {
        ...state,
        salesPersonRefDetails: action.payload,
      };
    case PATCH_DOCUMENT_DETAILS:
      return {
        ...state,
        documentDetails: action.payload,
      };
    default:
      return state;
  }
};

export const CreateRequestContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(createRequestReducer, INITIAL_STATE);

  return (
    <CreateRequestContext.Provider value={{ state, dispatch }}>
      {children}
    </CreateRequestContext.Provider>
  );
};

export const useCreateRequestContext = () => useContext(CreateRequestContext);
