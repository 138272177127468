import Logo from "assets/svg/logo.svg";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { LANGUAGES, useTranslation } from "contexts/TranslationContext";
import { useMemo } from "react";

const LanguageDropdown = () => {
  const { language, setLanguage } = useTranslation();

  const languages = useMemo(() => Object.keys(LANGUAGES).map(key => ({
    key: LANGUAGES[key]?.code,
    label: (
      <span onClick={() => setLanguage(LANGUAGES[key])}>{LANGUAGES[key]?.name}</span>
    ),
    disabled: language?.code === LANGUAGES[key]?.code,
  })), [language, setLanguage]);

  return (
    <>
      <Dropdown menu={{ items: languages }}>
        <Space className="text-white">
          <span className="font-bold">{language?.shortname}</span>
          <DownOutlined />
        </Space>
      </Dropdown>
    </>
  );
};

export function Header() {
  const { translate } = useTranslation();

  const navigation = [
/*     { label: translate('NAV.HOME'), path: "", },
    { label: translate('NAV.APPLY'), path: "", },
    { label: translate('NAV.HOW_IT_WORKS'), path: "", },
    { label: translate('NAV.ABOUT'), path: "", },
    { label: translate('NAV.FINANCIAL_PRODUCT'), path: "", },
    { label: translate('NAV.CONTACT'), path: "", },
    { label: translate('NAV.FAQS'), path: "", }, */
  ];

  return (
    <header className=" z-10 tamweel-container  px-6">
      <div className="flex  justify-between pt-7">
        <img src={Logo} alt="Logo" />

        <div className="flex w-96"></div>
        <ul className="hidden gap-16 lg:flex">
        {
          navigation.map(({ label }, index) => (
            <li key={index} className="text-white text-sm">{label}</li>
          ))
        }
        </ul>
        <LanguageDropdown />
      </div>
    </header>
  );
}
