import { LoadingOutlined } from "@ant-design/icons";
import { Col, Input, InputNumber, Row, Typography } from "antd";
import { Formik } from "formik";
import { useRef } from "react";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  useForgotPasswordMutation,
  useForgotPasswordVerificationMutation,
  useResetPasswordMutation,
} from 'services/auth';
import { Button } from "shared/components/Button";
import { errors } from "shared/constants/errors";
import routes from "shared/constants/routes";
import { Header } from "shared/views/Header";
import * as Yup from 'yup';
import { useTranslation } from 'contexts/TranslationContext';
const { Text } = Typography;

const ForgotPasswordContext = createContext(null);

const INITIAL_STATE = {
  step: 0,
  phone: '',
  password: '',
  otp: '',
};

const FLUSH = 'FLUSH';
const SET_PHONE = 'SET_PHONE';
const SET_OTP = 'SET_OTP';
const SET_PASSWORD = 'SET_PASSWORD';
const SET_STEP = 'SET_STEP';
const SET_TOKEN = 'SET_TOKEN';

const forgotPasswordReducer = (state, action) => {
  switch (action.type) {
    case FLUSH:
      return {
        ...INITIAL_STATE
      };
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

const Identification = () => {
  const { dispatch } = useContext(ForgotPasswordContext);
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
const {translate,language} = useTranslation();
  const schema = Yup.object().shape({
    phone: Yup.string()
      .length(9, 'Must be exactly 9 digits')
      .required('Cannot be empty'),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await forgotPassword({
        ...values,
        phone: '966' + values?.phone
      }).unwrap();

      if (response?.isOTPSend) {
        dispatch({ type: SET_STEP, payload: 1 });
        dispatch({ type: SET_PHONE, payload: values.phone });
        toast.success('Credentials verified successfully');
      } else {
        resetForm();
        toast.error(response?.message || 'Phone number does not exist.');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const d = language?.dir === 'rtl' ? {addonAfter:"+966"} :{ addonBefore:"+966"}; 
  return (
    <>
      <div className="text-gray-400">{translate("FORGOT_PASSWORD.Please, enter your phone")}</div>
      <Formik initialValues={{ phone: '' }} onSubmit={onSubmit} validationSchema={schema}>
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
   
            <div>
              <Input
                dir={"ltr"}
               {...d}
                
                status={errors.phone ? "error" : null}
                name="phone"
                id="phone"
                placeholder="5XXXXXXXX"
                onChange={handleChange}
                value={values.phone} />
              {
                errors.phone && <Text type="danger">{translate("FORGOT_PASSWORD." + errors.phone)}</Text>
              }
            </div>
            <Button type="submit" disabled={isLoading} block>
              {
                isLoading ? <LoadingOutlined /> :  translate("FORGOT_PASSWORD.Send")
              }
            </Button>
            <Link className="text-primary text-center text-sm block font-bold" to={routes.AUTH.SIGN_IN}>
              <span>{translate('FORGOT_PASSWORD.Return to sign in')}</span>
            </Link>
          </form>
        )}
      </Formik>
    </>
  );
};

const OTP = () => {
  const [seconds, setSeconds] = useState(60);
  const [otp, setOtp] = useState(['', '', '', '']);

  const otpRef1 = useRef(null);
  const otpRef2 = useRef(null);
  const otpRef3 = useRef(null);
  const otpRef4 = useRef(null);

  const { state, dispatch } = useContext(ForgotPasswordContext);

  const [verify, { isLoading }] = useForgotPasswordVerificationMutation();

  const countdown = (intervalId) => {
    if (seconds <= 0) {
      dispatch({ type: SET_STEP, payload: 0 });
      toast.error('Timeout. Please, sign-in again');
      clearInterval(intervalId);
    } else {
      setSeconds(seconds - 1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      countdown(intervalId);
    }, 1000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [seconds]); // eslint-disable-line

  const handleChange = (value, index) => {
    setOtp(otp => otp.map((_, i) => index === i ? value : _));

    switch (index) {
      case 0:
        otpRef2.current.focus();
        break;
      case 1:
        otpRef3.current.focus();
        break;
      case 2:
        otpRef4.current.focus();
        break;
      default:
        return;
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const response = await verify({
        otp: otp.join(''),
        phone: '966' + state?.phone,
      }).unwrap();

      if (response?.verified) {
        dispatch({ type: SET_OTP, payload: otp.join('') });
        dispatch({ type: SET_STEP, payload: 2 });
        toast.success('Credentials verified successfully');
      } else {
        dispatch({ type: SET_STEP, payload: 0 });
        toast.error('Invalid OTP');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const goBack = () => {
    dispatch({ type: FLUSH });
  };

  return (
    <>
      <div className="text-gray-400">We've sent your one-time password (OTP) by SMS</div>
      <form onSubmit={onSubmit} className="space-y-4 md:space-y-6">
        <div dir='ltr'>
          <Row gutter={[12, 12]}>
            <Col span={6}>
              <InputNumber ref={otpRef1} onChange={value => handleChange(value, 0)} name="otp1" id="otp1" required />
            </Col>
            <Col span={6}>
              <InputNumber ref={otpRef2} onChange={value => handleChange(value, 1)} name="otp2" id="otp2" required />
            </Col>
            <Col span={6}>
              <InputNumber ref={otpRef3} onChange={value => handleChange(value, 2)} name="otp3" id="otp3" required />
            </Col>
            <Col span={6}>
              <InputNumber ref={otpRef4} onChange={value => handleChange(value, 3)} name="otp4" id="otp4" required />
            </Col>
          </Row>
        </div>
        <Button type="submit" disabled={isLoading} block>
          {
            isLoading ? <LoadingOutlined /> : 'Send'
          }
        </Button>
        <div className="font-bold text-3xl text-center text-gray-400">00:{String(seconds).padStart(2, '0')}</div>
        <div className="cursor-not-allowed text-center text-gray-400 font-bold text-sm">
          Resend code
        </div>
        <div onClick={goBack} className="cursor-pointer text-center text-primary font-bold text-sm">
          Back
        </div>
      </form>
    </>
  );
};

const NewPassword = () => {
  const { state, dispatch } = useContext(ForgotPasswordContext);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();

  const onSubmit = async (values, actions) => {
    try {
      const response = await resetPassword({
        phone: '966' + state?.phone,
        password: values.password,
        otp: state.otp,
      }).unwrap();

      if (response?.passwordUpdate) {
        toast.success('Password has been updated successfully');
        navigate(routes.AUTH.SIGN_IN);
      } else {
        toast.error('Password reset failed');
        dispatch({ type: SET_STEP, payload: 0 });
      }
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const goBack = () => {
    dispatch({ type: FLUSH });
  };

  const schema = Yup.object().shape({
    password: Yup.string().required(),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords does not match'),
  });

  return (
    <>
      <div className="text-gray-400">Please, enter your new password</div>
      <Formik onSubmit={onSubmit} initialValues={{ confirmPassword: '', password: '' }} validationSchema={schema}>
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
          <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
            <div>
              <Input.Password name="password" id="password" placeholder="Password" onChange={handleChange} value={values.password} />
            </div>
            <div>
              <Input.Password
                status={errors.confirmPassword ? "error" : null}
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm password"
                onChange={handleChange}
                value={values.confirmPassword} />
              {
                errors.confirmPassword && <Text type="danger">{errors.confirmPassword}</Text>
              }
            </div>
            <Button type="submit" disabled={isLoading} block>
              {
                isLoading ? <LoadingOutlined /> : 'Reset'
              }
            </Button>
            <div onClick={goBack} className="cursor-pointer text-center text-primary font-bold text-sm">
              Back
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default function ForgotPassword() {
  const [state, dispatch] = useReducer(forgotPasswordReducer, INITIAL_STATE);
  const { translate } = useTranslation();
  return (
    <ForgotPasswordContext.Provider value={{ state, dispatch }}>
      <section className="bg-primary with-splash-bg h-screen">
        <div className="relative">
          <Header />
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="relative z-20 w-full bg-white rounded-lg md:mt-0 sm:max-w-md">
              <div className="p-6 rounded space-y-4 md:space-y-6 sm:p-8 bg-[#FAFAFA]">
                <h1 className="text-sm font-bold leading-tight tracking-tight text-primary uppercase md:text-2xl">
                 { translate("FORGOT_PASSWORD.Reset password")}
                </h1>
                <div className="bg-secondary w-16 h-1"></div>
                <h2 className="text-black text-3xl font-bold">
                  <div>  { translate("FORGOT_PASSWORD.Forgot password?")}</div>
                </h2>
                { state?.step === 0 && <Identification /> }
                { state?.step === 1 && <OTP /> }
                { state?.step === 2 && <NewPassword /> }
              </div>
            </div>
          </div>
        </div>
      </section>
    </ForgotPasswordContext.Provider>
  );
}
